export const SEO_DATA = {
  WEBINAR_JOIN: {
    es: {
      title: "Webinar - Ingreso",
      description: "Ingresa a este webinar",
    },
    en: {
      title: "Webinar - Join",
      description: "Join this webinar",
    },
  },
  WEBINAR_REGISTRATION: {
    es: {
      title: "Webinar - Registro",
      description: "Participa en este webinar",
    },
    en: {
      title: "Webinar - Registration",
      description: "Participate in this webinar",
    },
  },
}
