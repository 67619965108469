import * as React from "react"
import cx from "classnames"
import "./button.scss"

const Button = props => {
  const {
    children = "",
    className,
    disabled = false,
    inverse = false,
    outline = false,
    size,
    typeButton,
    ...otherButtonProps
  } = props
  return (
    <button
      {...otherButtonProps}
      type="button"
      className={cx(
        "button",
        className && className,
        typeButton && `button__${typeButton}`,
        inverse && `button__inverse`,
        outline && `button__outline`,
        size && `button__${size}`
      )}
      disabled={disabled}
    >
      {children && children}
    </button>
  )
}

export default Button
