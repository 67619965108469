import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import LanguageContext from "../contexts/language"
import Footer from "../components/footer"
import Seo from "../components/seo"
import HeaderWhite from "../components/HeaderWhite/HeaderWhite"
import RegisterForm from "../components/form/RegisterForm"
import { SEO_DATA } from "../components/seo/seoData"

import Layout from "../components/layout"
const isBrowser = typeof window !== "undefined"

const WebinarJoin = props => {
  const { data, uid } = props.data.prismicWebinarJoin
  const { lang, theme, accesibilitySize } = useContext(LanguageContext)

  const [themeState, setThemeState] = useState(theme)
  const [accesibilitySizeState, setAccesibilitySizeState] = useState(
    accesibilitySize
  )

  const seo = SEO_DATA.WEBINAR_JOIN[lang]

  useEffect(() => {
    if (isBrowser && themeState) localStorage.setItem("foris_theme", themeState)
  }, [themeState])

  useEffect(() => {
    if (isBrowser && accesibilitySizeState)
      localStorage.setItem("accesibility_size", accesibilitySizeState)
  }, [accesibilitySizeState])

  return (
    <Layout
      {...props}
      theme={themeState}
      accesibilitySize={accesibilitySizeState}
    >
      <Seo title={seo.title} desc={seo.description} />
      <HeaderWhite
        className="albusPage_header"
        onclick={() =>
          themeState === "theme-light"
            ? setThemeState("theme-dark")
            : setThemeState("theme-light")
        }
        onClickPlus={() => {
          if (accesibilitySizeState < 2) {
            setAccesibilitySizeState(accesibilitySizeState + 1)
          }
        }}
        onClickMinus={() => {
          if (accesibilitySizeState => 2) {
            setAccesibilitySizeState(accesibilitySizeState - 1)
          }
        }}
      />
      <main id="app" className="webinarJoinPage" role="main">
        <section className="webinarJoinPage_hero">
          <span className="topLabel">{data.hero_top_label}</span>
          <h1 className="title">{data.hero_title.text}</h1>
        </section>
        <section className="webinarJoinPage_form">
          <RegisterForm
            title={data.form_title.text}
            confirmButtonText={data.form_button_text}
            onConfirm={() => {
              window.open(data.form_button_link.url, "_blank")
            }}
            uid={`register-${uid}`}
          />
        </section>
      </main>
      <Footer className="albusPage_footer" />
    </Layout>
  )
}

export default WebinarJoin

export const pageQuery = graphql`
  query WebinarJoin($lang: String!, $uid: String!) {
    prismicWebinarJoin(lang: { eq: $lang }, uid: { eq: $uid }) {
      uid
      data {
        hero_top_label
        hero_title {
          text
        }
        form_title {
          text
        }
        form_name_label
        form_email_label
        form_position_label
        form_button_text
        form_button_link {
          url
        }
      }
    }
  }
`
