import React, { useRef, useState } from "react"
import cx from "classnames"
import Button from "../Button/Button"
import "./form.scss"

const RegisterForm = props => {
  const {
    className,
    title,
    confirmButtonText,
    onConfirm,
    cancelButtonText,
    onCancel,
    uid,
  } = props

  const registerForm = useRef(null)
  const [nameInvalid, setNameInvalid] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [universityInvalid, setUniversityInvalid] = useState(false)

  const runValidations = () => {
    setNameInvalid(false)
    setEmailInvalid(false)
    setUniversityInvalid(false)

    const formElements = registerForm.current.elements
    const nameValue = formElements.registerFormName.value
    const emailValue = formElements.registerFormEmail.value
    const universityValue = formElements.registerFormUniversity.value

    if (
      nameValue.length > 0 &&
      emailValue.length > 0 &&
      universityValue.length > 0
    ) {
      registerForm.current.submit()
      onConfirm && onConfirm()
    } else {
      if (nameValue.length === 0) {
        setNameInvalid(true)
      }
      if (emailValue.length === 0) {
        setEmailInvalid(true)
      }
      if (universityValue.length === 0) {
        setUniversityInvalid(true)
      }
    }
  }

  return (
    <div className={cx("wrapperForm wrapperForm__brochure", className && className)} id="register">
      <div className="wrapperForm_title">
        <h2 className="mainTitle">{title}</h2>
      </div>
      <form
        className="wrapperForm_content"
        name="register"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref={registerForm}
      >
        <div>
          <input type="hidden" name="form-name" value="register" />
          <p className="hidden">
            <label>
              Don't fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
        </div>
        <div>
          <input type="hidden" name="url" value={uid} />
        </div>
        <div className={cx("formField", nameInvalid && "formField__error")}>
          <label className="formField_label" htmlFor="registerFormName">
            Nombre
          </label>
          <input
            className="formField_input"
            id="registerFormName"
            name="name"
            placeholder="Nombres Apellidos"
            type="text"
          />
          {nameInvalid && <p className="formField_message">Error</p>}
        </div>
        <div
          className={cx("formField", universityInvalid && "formField__error")}
        >
          <label className="formField_label" htmlFor="registerFormUniversity">
            Universidad / Empresa
          </label>
          <input
            className="formField_input"
            id="registerFormUniversity"
            name="university"
            placeholder="Nombre de la universidad"
            type="text"
          />
          {universityInvalid && <p className="formField_message">Error</p>}
        </div>
        <div className="formField">
          <label className="formField_label" htmlFor="registerFormPosition">
            Cargo <span className="optionalField">(opcional)</span>
          </label>
          <input
            className="formField_input"
            id="registerFormPosition"
            name="position"
            placeholder="Ingrese un cargo"
            type="text"
          />
        </div>
        <div className={cx("formField", emailInvalid && "formField__error")}>
          <label className="formField_label" htmlFor="registerFormEmail">
            Correo
          </label>
          <input
            className="formField_input"
            id="registerFormEmail"
            name="email"
            placeholder="correo@dominio.com"
            type="email"
          />
          {emailInvalid && <p className="formField_message">Error</p>}
        </div>
        <div className="formButtons">
          <Button
            className="formButtons_button"
            onClick={() => runValidations()}
            typeButton="accent"
          >
            {confirmButtonText}
          </Button>
          {cancelButtonText && (
            <Button
              className="formButtons_button"
              onClick={() => {
                registerForm.current.reset()
                onCancel && onCancel()
              }}
              typeButton="accent"
              inverse
            >
              {cancelButtonText}
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default RegisterForm
